
import { Component, Vue } from 'vue-property-decorator'
import { MaintenanceForm } from '@/types/common'
import { TableInfo } from '@/types/serviceFacility'
import MaintenanceDialog from '@/components/maintenanceDialog/Index.vue'

@Component({
  name: 'ServiceFacilityList',
  components: { MaintenanceDialog }
})
export default class extends Vue {
  private showDialog = false
  private isBatch = false
  private selects: TableInfo[] = []
  private searchInfo = {
    projectName: '',
    serviceName: ''
  }

  private curId=''

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData: TableInfo[] = []

  created () {
    this.getData()
  }

  // 添加养护记录
  addMaintenance (id: string) {
    this.curId = id
    this.showDialog = true
  }

  // 批量添加养护记录
  addMaintenanceBatch () {
    if (this.selects && this.selects.length > 0) {
      this.isBatch = true
      this.showDialog = true
    } else {
      this.$message.warning('未选中服务设施，无法批量维护')
    }
  }

  // 勾选更改
  handleSelectionChange (val: Array<TableInfo>) {
    this.selects = val
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{total: number;list: Array<TableInfo>}>(this.$apis.serviceFacility.selectServiceFacilitiesByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增服务设施
  addService () {
    this.$router.push({ path: '/serviceFacility/add' })
  }

  goDetail (id: string) {
    this.$router.push({
      path: '/serviceFacility/detail/serviceDetail',
      query: { id }
    })
  }

  editService (id: string) {
    this.$router.push({ name: 'serviceFacilityEdit', params: { id } })
  }

  deleteService (serviceId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.serviceFacility.deleteServiceFacilities, { serviceId })
          .then(() => {
            this.$message.success('删除成功')
            this.searchData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  closeDialog () {
    this.showDialog = false
  }

  saveData (formData: MaintenanceForm) {
    let url = this.$apis.record.insertMaintainRecord
    let info: { objectId?: string; objectIds?: Array<string> } = { objectId: this.curId }
    if (this.isBatch) {
      url = this.$apis.record.insertAllMaintainRecord
      info = { objectIds: [] }
      this.selects.forEach(item => {
        (info.objectIds as Array<string>).push(item.serviceId)
      })
    }
    this.$axios.post(url, {
      ...info,
      ...formData
    }).then(() => {
      this.$message.success('添加成功')
      this.getData()
    }).finally(() => {
      this.showDialog = false
      this.isBatch = false
    })
  }
}
